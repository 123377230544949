import React, { useEffect, useState, forwardRef } from 'react';
import {
    Box,
    Dialog,
    Typography,
    Button,
    Tabs,
    Tab,
    FormControlLabel,
    Switch,
} from '@mui/material';
import Image from 'next/image';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';

const DevelopeDialog = ({ open, handleClose }) => {
    const { t } = useTranslation();
    return (
        <Dialog
            onClose={handleClose}
            open={open}
            // classes={{
            //     paper: {
            //         borderRadius: 10,
            //         display: 'flex',
            //         flexDirection: 'column',
            //     },
            // }}
            // sx={{ padding: 40 }}
        >
            <Box className="p-10">
                <Image
                    width={120}
                    height={48} // Specify the height here
                    // height={48}
                    src={i18n.language === 'mn' ? '/LogoFull.png' : '/LogoFullEng.png'}
                    // src={i18n.language === 'mn' ? require('public/LogoFull.png') : require('public/LogoFull.png')}
                    // src={require('public/cover.png')}
                    alt=""
                    style={{ objectFit: 'cover' }}
                />
                <Typography variant={'body13'} className="ml-10 text-primary-700 cursor-pointer">
                    {t('title.developing')}
                </Typography>
            </Box>
            {/* <Box
                style={{
                    marginLeft: 'auto',
                }}
            >
                <Button
                    style={{
                        paddingTop: 9,
                        paddingBottom: 9,
                        paddingLeft: 23,
                        paddingRight: 23,
                        borderRadius: 4,
                        fontWeight: '700',
                        fontSize: 12,
                        color: '#6B6F7E',
                    }}
                    onClick={handleClose}
                >
                    Хаах
                </Button>
            </Box> */}
        </Dialog>
    );
};

export default DevelopeDialog;
