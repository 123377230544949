import { Snackbar, Slide } from '@mui/material';

import { Alert } from '@mui/material';


const CustomSnackBar = ({ open, handleClose, msg, type = 'success' }) => {

    return (
        <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={open}
            onClose={handleClose}
            // message={snack.msg}
            TransitionComponent={Slide}
            autoHideDuration={1600}

            // key={vertical + horizontal}
        >
            <Alert
                // onClose={handleCloseSnack}
                severity={type}
                variant="filled"
                sx={{ width: '100%' }}
            >
                {msg}
            </Alert>
        </Snackbar>
    );
};

export default CustomSnackBar;
