import { get, post, put, destroy, upload } from 'utils/fetchRequest';

export const uploadImage = async (data) => {
    try {
        const result = await upload('files/languages', data);

        return result;
    } catch (err) {
        return { error: err };
    }
};

// export const getMenusList = async (searchText) => {
//     try {
//         const result = await get(`menus${searchText ? `?search=${searchText}` : ''}`);

//         return result;
//     } catch (err) {
//         return { error: err };
//     }
// };

export const getLangContent = async (pageId) => {
    try {
        const result = await get(`languages/${pageId}`);
        return result;
    } catch (err) {
        return { error: err };
    }
};

export const getLangList = async (query) => {
    try {
        const result = await get(`languages?1=1${query ? query : ''}`);

        return result;
    } catch (err) {
        return { error: err };
    }
};

export const getLangAllList = async (query = '') => {
    try {
        const result = await get(`languages/all` + query);

        return result;
    } catch (err) {
        return { error: err };
    }
};

export const createLang = async (data) => {
    try {
        const result = await post('languages', data);

        return result;
    } catch (err) {
        return { error: err };
    }
};

export const updateLang = async (id, data) => {
    try {
        const result = await put(`languages/${id}`, data);

        return result;
    } catch (err) {
        return { error: err };
    }
};

export const deleteLang = async (id) => {
    try {
        const result = await destroy(`languages/${id}`);

        return result;
    } catch (err) {
        return { error: err };
    }
};

export const updateLangContent = async (id) => {
    try {
        const result = await put(`languages/languagesContent/${id}`);

        return result;
    } catch (err) {
        return { error: err };
    }
};
