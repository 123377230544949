import { useEffect, useState } from 'react';
import BreakPoints from 'constants/theme/breakPoints';

export default function useWindowSize() {
    // const isSSR = process.browser ? true : false;

    const [windowSize, setWindowSize] = useState({
        width: 1200,
        height: 800,
        sizes: {},
    });

    const Sizes = ['xl', 'lg', 'md', 'sm', 'xs'];

    useEffect(() => {}, [windowSize]);

    useEffect(() => {
        if (typeof window !== 'undefined') {
            function handleResize() {
                let passesdCurrentSize = false;
                let sizes = {};

                for (let i = 0; i < Sizes.length; i++) {
                    if (window.innerWidth >= BreakPoints[Sizes[i]] && !passesdCurrentSize) {
                        passesdCurrentSize = true;
                        sizes[Sizes[i]] = true;
                    } else {
                        sizes[Sizes[i]] = !passesdCurrentSize;
                    }
                }

                setWindowSize({
                    width: window.innerWidth,
                    height: window.innerHeight,
                    sizes,
                });
            }

            window.addEventListener('resize', handleResize);

            handleResize();

            return () => window.removeEventListener('resize', handleResize);
        }
    }, []);

    return windowSize;
}
