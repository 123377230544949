import { get, post, put, destroy, upload } from 'utils/fetchRequest';

export const uploadImage = async (data) => {
    try {
        const result = await upload('files/categories', data);

        return result;
    } catch (err) {
        return { error: err };
    }
};

export const getCategoriesContent = async (CategoriesId) => {
    try {
        const result = await get(`categories/${CategoriesId}`);
        return result;
    } catch (err) {
        return { error: err };
    }
};

export const getCategoriesList = async (isHeader, query) => {
    try {
        const result = await get(
            `categories?1=1${isHeader === true ? `&isHeader=1` : isHeader === false ? '&isHeader=0' : ''}${query ? query : ''}`
        );

        return result;
    } catch (err) {
        return { error: err };
    }
};

export const getCategoriesAllList = async (query = '') => {
    try {
        const result = await get(`categories/all` + query);

        return result;
    } catch (err) {
        return { error: err };
    }
};

export const createCategories = async (data) => {
    try {
        const result = await post('categories', data);

        return result;
    } catch (err) {
        return { error: err };
    }
};

export const updateCategories = async (id, data) => {
    try {
        const result = await put(`categories/${id}`, data);

        return result;
    } catch (err) {
        return { error: err };
    }
};

export const deleteCategories = async (id) => {
    try {
        const result = await destroy(`categories/${id}`);

        return result;
    } catch (err) {
        return { error: err };
    }
};

export const updateCategoriesContent = async (id) => {
    try {
        const result = await put(`categories/categoriesContent/${id}`);

        return result;
    } catch (err) {
        return { error: err };
    }
};
