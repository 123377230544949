import { get, post, put, destroy, upload } from 'utils/fetchRequest';

export const getAdsList = async (query) => {
    try {
        const result = await get(`ads?1=1${query ? query : ''}`);

        return result;
    } catch (err) {
        return { error: err };
    }
};

export const getAdsAllList = async (query = '') => {
    try {
        const result = await get(`ads` + query);

        return result;
    } catch (err) {
        return { error: err };
    }
};

export const createAds = async (data) => {
    try {
        const result = await post('ads', data);

        return result;
    } catch (err) {
        return { error: err };
    }
};

export const updateAds = async (id, data) => {
    try {
        const result = await put(`ads/${id}`, data);

        return result;
    } catch (err) {
        return { error: err };
    }
};

export const deleteAds = async (id) => {
    try {
        const result = await destroy(`ads/${id}`);

        return result;
    } catch (err) {
        return { error: err };
    }
};
