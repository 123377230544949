export const isRegisterWrong = (register) => {
    if (!register || register.length !== 10) {
        return true;
    }

    const upper = register.toUpperCase();
    const firstCharCode = upper.charCodeAt(0);
    const secondCharCode = upper.charCodeAt(1);

    if (firstCharCode < 1040 || firstCharCode > 1071) {
        return true;
    }

    if (secondCharCode < 1040 || secondCharCode > 1071) {
        return true;
    }

    const dateString = upper.substring(2);
    const dateNumber = parseInt(dateString);
    if (isNaN(dateNumber)) {
        return true;
    }

    const year = parseInt(dateString.substring(0, 2));
    const month = parseInt(dateString.substring(2, 4));
    const day = parseInt(dateString.substring(4, 6));

    if (year < 50) {
        if (month < 21 || month > 32) return true;
    } else {
        if (month < 1 || month > 12) return true;
    }

    if (day < 1 || day > 31) {
        return true;
    }

    return false;
};

export const isMobileWrong = (mobileText) => {
    const mobile = parseInt(mobileText);
    if (mobile.toString().length === 8) return false;
    return true;
};

export const validatePassword = (password) => {
    if (!/\d/.test(password)) return false;

    if (!/[a-zA-Z]/.test(password)) return false;

    if (password.length < 8) return false;

    return true;
};

export const validateEmail = (mail) => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
        return true;
    }
    return false;
};

export const scrollToId = (id, offset = 0) => {
    const element = document.querySelector(`#${id}`);

    window.scrollTo({
        left: 0,
        top: element.offsetTop + offset,
        behavior: 'smooth',
    });
};

export const formatCurrency = (value, type) => {
    if (type === 'usd') {
        const formattedValue = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0, // No decimal places
            maximumFractionDigits: 0, // No decimal places
        }).format(value);
        return `${formattedValue.replace('₮', '').trim()}`;
    } else {
        const formattedValue = new Intl.NumberFormat('mn-MN', {
            style: 'currency',
            currency: 'MNT',
            minimumFractionDigits: 0, // No decimal places
            maximumFractionDigits: 0, // No decimal places
        }).format(value);
        return `${formattedValue.replace('MNT', '').trim()}₮`;
    }
};
