import React, { useContext, useState } from "react";

import { GlobalContext } from "context";
// component
import Header from "components/Header";
import Footer from "components/Footer";
import Image from "next/image";
import DevelopeDialog from "components/DevelopDialog";
import LoadingDialog from "components/global/LoadingDialog";
import CustomSnackBar from "components/CustomSnackBar";
import { SHOW_DEVELOP, SET_ALERT } from "context/actions/system";
import { Facebook, Instagram } from "react-feather";
// import Alert from '@mui/material/Alert';
import { Box, Typography, Container, Button } from "@mui/material";

export default function HomeLayout({ children }) {
  const { state, dispatch } = useContext(GlobalContext);
  const [showBarcode, setShowBarcode] = useState(false);

  const handleClick = () => {
    setShowBarcode(!showBarcode);
  };
  return (
    <>
      <div>
        <main>
          <Header />
          <div>{children}</div>
          <Footer />
          {state.system.showDevelop && (
            <DevelopeDialog
              open={state.system.showDevelop}
              handleClose={() => {
                console.log("asf");
                dispatch({ type: SHOW_DEVELOP, data: false });
              }}
            />
          )}

          {state.system.showLoading && (
            <LoadingDialog open={state.system.showLoading} />
          )}
          {state.system.alert.isShow && (
            // <Alert severity={state.system.alert.type} variant="outlined" className="">
            //     asf
            // </Alert>

            <CustomSnackBar
              open={state.system.alert.isShow}
              msg={state.system.alert.message}
              handleClose={() => {
                dispatch({
                  type: SET_ALERT,
                  data: { isShow: false, type: "", message: "" },
                });
              }}
              type={state.system.alert.type}
            />
          )}
        </main>
        <Box className="fixed bottom-[300px] right-[30px] flex flex-col z-[9893]">
          <a
            href="https://www.facebook.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Box className="bg-[#0966ff] h-[50px] w-[50px] flex justify-center items-center rounded-[50px]">
              <Facebook color="#fff" size={30} />
            </Box>
          </a>
        </Box>
        <Box className="fixed bottom-[240px] right-[30px] flex flex-col z-[9893]">
          <Box
            className="bg-[#0966ff] h-[50px] w-[50px] flex justify-center items-center rounded-[50px]"
            onClick={handleClick} // Toggle barcode on click
          >
            <Image
              width={1}
              height={1}
              src={"/pages/home/wechat.png"} // Path to your WeChat image
              layout="responsive"
              priority
              alt="WeChat"
              className="object-contain rounded-[50px]"
            />
          </Box>

          {/* Conditionally render the barcode */}
          {showBarcode && (
            <Box className="mt-4 flex justify-center">
              <img
                src={"/pages/home/wechat.png"} // Path to your WeChat image
                alt="WeChat Barcode"
                className="w-[200px] h-[200px] object-contain"
              />
            </Box>
          )}
        </Box>
      </div>
    </>
  );
}
