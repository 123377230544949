import React, { useEffect, useState, useContext, useRef } from "react";
import Cookies from "js-cookie";
import { useRouter } from "next/router";
import Image from "next/image";
import Link from "next/link";
import { ChevronDown, Mail } from "react-feather";
import clsx from "clsx";
import {
  Container,
  Box,
  Typography,
  Drawer,
  Menu,
  MenuItem,
} from "@mui/material";
import { CHANGE_LANG } from "context/actions/system";
import { logoutAccount } from "context/actions/auth";

import { GlobalContext } from "context";
import i18n from "i18next";
import { useTranslation } from "react-i18next";

// utils
import useWindowSize from "utils/useWindowSize";
import { scrollToId } from "utils/functions";

import { SHOW_DEVELOP } from "context/actions/system";
import { getMailList } from "context/actions/mails";

import {
  X,
  Menu as MenuIcon,
  Key,
  ChevronRight,
  ChevronLeft,
} from "react-feather";
//actions

import { getPageList } from "context/actions/pages";
import { getAdsList } from "context/actions/ads";
import { getLangList } from "context/actions/languages";
import { getCategoriesList } from "context/actions/categories";

const HeaderHeight = 80;

function useOutsideClick(ref, secondRef, callback) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        (!secondRef ||
          (secondRef.current && !secondRef.current.contains(event.target)))
      ) {
        callback();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, callback]);
}

const Header = () => {
  const { sizes } = useWindowSize();
  const { t } = useTranslation();
  const router = useRouter();
  const langRef = useRef();
  const menuRef = useRef();
  const mainMenuRef = useRef();

  const [scrollTop, setScrollTop] = useState(0);

  const { state, dispatch } = useContext(GlobalContext);

  // console.log('HEADER', state);

  const [showMenu, setShowMenu] = useState(false);

  const [menus, setMenus] = useState([]);
  const [langs, setLangs] = useState([]);
  const [ads, setAds] = useState([]);
  const [categories, setCategories] = useState([]);
  const [chosedLang, setChoosedLang] = useState(null);
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [showLangList, setShowLangList] = useState(false);
  const [showSubMenu, setShowSubMenu] = useState(false);
  const [subMenuDirection, setSubMenuDirection] = useState("column");
  const [subMenu, setSubMenu] = useState(null);

  useOutsideClick(langRef, null, () => {
    setShowLangList(false);
  });

  useOutsideClick(menuRef, mainMenuRef, () => {
    setShowSubMenu(false);
    setSubMenu(null);
  });

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (state.auth?.accountLoggedIn) setLoggedIn(true);
    else setLoggedIn(false);
  }, [state.auth]);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollTop(position);
  };

  useEffect(() => {
    getDatas();
  }, []);

  useEffect(() => {
    if (langs?.length) {
      langs.map((lng) => {
        if (lng.code === state.system.lang) setChoosedLang(lng);
      });
    }
  }, [langs]);

  const getDatas = async () => {
    const datas = await getPageList(true, "", dispatch);
    if (datas.status === "success") {
      setMenus(datas.data);
    }
    const languages = await getLangList();
    if (languages.status === "success") {
      setLangs(languages.data);
    }

    const resAds = await getAdsList(`&limit=1`);
    if (resAds.status === "success") setAds(resAds.data);

    const categoriesRes = await getCategoriesList(null, "&fromMenu=1");
    if (categoriesRes.status === "success") {
      setCategories(categoriesRes.data);
    }
  };

  const handleMenuButtonClick = () => {
    setShowMenu((prev) => !prev);
  };

  const handleDrawerClose = (force) => {
    if (force === true) {
      setShowMenu(false);
      setShowSubMenu(false);
    } else if (showSubMenu) setShowSubMenu(false);
    else {
      setShowMenu(false);
      setShowSubMenu(false);
    }
  };

  const handleUrlClick = (url) => {
    window.open(url, "_blank");
  };

  const handleRoteClick = (menu) => {
    showMenu && handleDrawerClose(true);

    router.push(menu.url ? "/" + menu.url : "/content/" + menu.id);
    // scrollToId(route, -HeaderHeight);
  };

  const RenderMenu = ({
    menu,
    showsMenu = true,
    type = "header",
    sMenuDirection = "column",
  }) => {
    const onMouseEnter = (e) => {
      setSubMenu(menu);
      setSubMenuDirection(sMenuDirection);
      setShowSubMenu(true);
    };
    const onMouseLeave = (e) => {
      setShowSubMenu(false);
      setSubMenu(null);
    };

    return (
      <div onClick={onMouseEnter}>
        <li className="relative flex justify-start items-center px-2.5 cursor-pointer text-gray-950 hover:text-primary uppercase text-sm">
          {menu.children && menu.children.length > 0 ? (
            menu.names.find((item) => item.langCode === i18n.language)?.name
          ) : (
            <Link
              href={menu.url ? "/" + menu.url : "/content/" + menu.id}
              passHref
            >
              {menu.names.find((item) => item.langCode === i18n.language)?.name}
            </Link>
          )}
        </li>
      </div>
    );
  };

  const RenderSubMenu = ({
    menu,
    showsMenu = true,
    textStyle = "",
    style = "",
  }) => {
    const [showSubMenu2, setShowSubMenu2] = useState(false);

    const onMouseEnter = (e) => {
      setShowSubMenu2(true);
    };
    const onMouseLeave = (e) => {
      setShowSubMenu2(false);
    };

    const hanldeClick = () => {
      setShowSubMenu(false);
      setSubMenu(null);
    };

    return (
      <Link href={menu.url ? "/" + menu.url : "/content/" + menu.id} passHref>
        <li
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          className={
            `relative text-gray cursor-pointer ${showsMenu ? "block" : "hidden"} py-2 ` +
            style
          }
          onClick={hanldeClick}
        >
          <Typography
            variant="body13"
            className={
              " text-gray-950 hover:text-primary uppercase text-sm  " +
              textStyle
            }
          >
            {menu.names.find((item) => item.langCode === i18n.language)?.name}
          </Typography>
          <div>
            {menu.children.map((m, ind) => {
              return (
                <RenderSubMenu
                  key={ind}
                  menu={m}
                  showsMenu={showSubMenu}
                  style="py-0"
                  textStyle="!text-[10px]"
                />
              );
            })}
          </div>
        </li>
      </Link>
    );
  };

  const RenderMenu2 = ({ menu, showsMenu = true, type = "header" }) => {
    const [showSubMenu2, setShowSubMenu2] = useState(false);

    return (
      <div
        className="w-full mt-2 px-10"
      >
        {
          <li
            className="relative h-[30px] flex justify-start items-center px-2.5 cursor-pointer text-gray text-sm bg-gray-50 py-6 uppercase px-6"
            onClick={() => {
              if (menu.children && !menu.children.length) {
                handleRoteClick(menu);
              } else {
                setSubMenu(menu);
                setShowSubMenu(true);
                // setShowSubMenu2(true);
              }
            }}
          >
            {menu.children && menu.children.length > 0 ? (
              menu.names.find((item) => item.langCode === i18n.language)?.name
            ) : (
              <Typography variant={"body14"} className="">
                {
                  menu.names.find((item) => item.langCode === i18n.language)
                    ?.name
                }
              </Typography>
            )}
            <ChevronRight
              color={"#000"}
              size={"24"}
              // onClick={handleDrawerClose}
              className="ml-auto"
            />
          </li>
        }
      </div>
    );
  };
  const RenderSubMenu2 = ({
    menu,
    showsMenu = true,
    style = "",
    textStyle = "",
  }) => {
    const [showSubMenu2, setShowSubMenu2] = useState(false);

    return (
      <li
        className={"relative text-gray cursor-pointer flex flex-col " + style}
      >
        {/* <Link href={menu.url ? '/' + menu.url : '/content/' + menu.id} passHref> */}

        <Typography
          variant={"body16"}
          onClick={() => handleRoteClick(menu)}
          className={"ml-2 my-2" + textStyle}
        >
          {menu.names.find((item) => item.langCode === i18n.language)?.name}
        </Typography>
        {/* <div style={{ position: 'absolute', top: 20 }}> */}
        {menu.children.map((m, ind) => {
          return (
            <RenderSubMenu2
              key={ind}
              menu={m}
              textStyle="ml-4 text-xs"
              style="bg-gray-50 p-2 m-1"
            />
          );
        })}
        {/* </div> */}
      </li>
    );
  };

  return (
    <>
      <Container maxWidth={false} disableGutters={true}>
        <Container
          maxWidth={false}
          disableGutters={true}
          className="flex bg-[#035dab] h-9 justify-center"
        >
          <Container
            maxWidth={"lg"}
            className="flex flex-row items-center justify-center relative"
          >
            {!sizes.sm && (
              <Box className="absolute right-0 flex flex-row items-center">
                {chosedLang && (
                  <Box
                    ref={langRef}
                    className="relative flex flex-row items-center cursor-pointer"
                    onClick={() => {
                      if (showLangList) setShowLangList(false);
                      else setShowLangList(true);
                    }}
                  >
                    <Image
                      width={20}
                      height={20}
                      src={chosedLang?.flagUrl}
                      alt=""
                      className="object-conatin"
                      onClick={() => {}}
                      priority
                      style={{ width: 20, height: 20, border:'1px solid white',borderRadius:100 }}
                    />

                    <Typography variant={"h14"} className="text-white !ml-2">
                      {chosedLang?.name}
                    </Typography>

                    {showLangList && (
                      <Box
                        className={
                          "absolute flex flex-col p-2 pr-4 -left-[9px] top-5 bg-tertiary-20 z-20"
                        }
                      >
                        {langs.map((lng, ind) => {
                          return (
                            <Box
                              key={ind}
                              className="flex flex-row p-1"
                              onClick={() => {
                                setChoosedLang(lng);
                                i18n.changeLanguage(lng.code);
                                if (typeof window !== "undefined") {
                                  Cookies.set("chosen_lang", lng.code);
                                }
                                // router.reload(window.location.pathname);
                                dispatch({
                                  type: CHANGE_LANG,
                                  data: lng.code,
                                });
                              }}
                            >
                              <Image
                                width={20}
                                height={20}
                                src={lng.flagUrl}
                                alt=""
                                className="object-conatin"
                                priority
                                style={{ width: 15, height: 15, marginTop:5 }}
                              />

                              <Typography
                                variant={"h14"}
                                className="text-black !ml-1"
                              >
                                {lng?.name}
                              </Typography>
                            </Box>
                          );
                        })}
                      </Box>
                    )}
                  </Box>
                )}
              </Box>
            )}
          </Container>
        </Container>
      </Container>
      <Container
        maxWidth={false}
        disableGutters={true}
        className="border-b border-b-gray-100"
      >
        <Container
          maxWidth={"lg"}
          className="flex flex-row items-center h-21 py-5"
        >
          {sizes.sm && (
            <MenuIcon
              color={"#000"}
              size={"22"}
              onClick={handleMenuButtonClick}
              className="mr-auto"
            />
          )}
          <Link
            href={"/"}
            passHref
            className={`mr-auto ${sizes.sm ? "-ml-10" : ""}`}
          >
            <Box>
              <Image
                width={sizes.sm ? 180 : 200}
                height={sizes.sm ? 48 : 60}
                src={
                  i18n.language === "mn" ? "/LogoFull.png" : "/LogoFullEng.png"
                }
                alt=""
                style={{ objectFit: "cover" }}
              />
            </Box>
          </Link>
          {!sizes.sm ? (
            <>
              <Box ref={mainMenuRef}>
                <ul
                  style={{
                    position: "relative",
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  {menus.map((m, ind) => {
                    return <RenderMenu key={ind} menu={m} />;
                  })}
                </ul>
              </Box>
            </>
          ) : (
            <>
              <Drawer
                anchor="left"
                onClose={handleDrawerClose}
                open={showMenu}
                transitionDuration={{
                  enter: 500,
                  exit: 500,
                }}
                variant="temporary"
                // variant="persistent"
                // variant="permanent"
                PaperProps={{
                  sx: {
                    width: "100vw", // Full width of the viewport
                    height: "100vh", // Full height if needed
                    maxWidth: "100%", // Prevent overflow
                  },
                }}
              >
                <Box className="flex flex-col justify-start items-center">
                  <Box className="flex flex-row justify-center w-full items-center h-21 py-5">
                    <ChevronLeft
                      color={"#000"}
                      size={"24"}
                      onClick={handleDrawerClose}
                      className="mr-auto !ml-4"
                    />
                    <Link href={"/"} passHref className="">
                      <Box>
                        <Image
                          width={180}
                          height={48} // Specify the height here
                          // height={48}
                          src={
                            i18n.language === "mn"
                              ? "/LogoFull.png"
                              : "/LogoFullEng.png"
                          }
                          alt=""
                          style={{ objectFit: "cover" }}
                        />
                      </Box>
                    </Link>
                    {chosedLang && (
                      <Box
                        ref={langRef}
                        className="relative flex flex-row  cursor-pointer ml-auto !mr-4"
                        onClick={() => {
                          if (showLangList) setShowLangList(false);
                          else setShowLangList(true);
                        }}
                      >
                        <Image
                          width={14}
                          height={14}
                          src={chosedLang?.flagUrl}
                          alt=""
                          className="object-conatin"
                          onClick={() => {}}
                          priority
                        />

                        <Typography
                          variant={"body10"}
                          className="text-gray-950 !ml-2 uppercase"
                        >
                          {chosedLang?.code}
                        </Typography>

                        {showLangList && (
                          <Box
                            className={
                              "absolute flex flex-col p-2 pr-4 -left-[9px] top-5 bg-tertiary-20"
                            }
                          >
                            {langs.map((lng, ind) => {
                              return (
                                <Box
                                  key={ind}
                                  className="flex flex-row p-1"
                                  onClick={() => {
                                    setChoosedLang(lng);
                                    i18n.changeLanguage(lng.code);
                                    if (typeof window !== "undefined") {
                                      Cookies.set("chosen_lang", lng.code);
                                    }
                                    // router.reload(window.location.pathname);
                                    dispatch({
                                      type: CHANGE_LANG,
                                      data: lng.code,
                                    });
                                  }}
                                >
                                  <Image
                                    width={7}
                                    height={7}
                                    src={lng.flagUrl}
                                    alt=""
                                    className="object-conatin"
                                    priority
                                  />

                                  <Typography
                                    variant={"body8"}
                                    className="text-black !ml-1"
                                  >
                                    {lng?.name}
                                  </Typography>
                                </Box>
                              );
                            })}
                          </Box>
                        )}
                      </Box>
                    )}
                  </Box>
                  {!showSubMenu && (
                    <>
                    
                      {menus.map((m, ind) => {
                        return <RenderMenu2 key={ind} menu={m} type="drawer" />;
                      })}
                    </>
                  )}
                  <div className="flex flex-col w-full px-10">
                    {showSubMenu && (
                      <Typography
                        variant="body16"
                        className={" text-primary uppercase ml-2 mb-6 mt-5"}
                      >
                        {
                          subMenu.names.find(
                            (item) => item.langCode === i18n.language
                          )?.name
                        }
                      </Typography>
                    )}
                    {showSubMenu &&
                      subMenu?.children.map((m, ind) => {
                        return <RenderSubMenu2 key={ind} menu={m} />;
                      })}
                  </div>
                </Box>
              </Drawer>
            </>
          )}
        </Container>
      </Container>
      {showSubMenu && subMenu && !sizes.sm && (
        <Container
          ref={menuRef}
          maxWidth={false}
          disableGutters={true}
          className="absolute z-[2] bg-white border-b border-b-gray-100"
        >
          <Container
            maxWidth={"lg"}
            className="flex flex-row items-center justify-center pl-28"
          >
            {subMenu?.children?.length > 0 && (
              <ul
                style={{
                  position: "relative",
                }}
                className={`flex ${subMenuDirection === "row" ? "flex-row" : "flex-col"}`}
              >
                {subMenu?.children?.map((m, ind) => {
                  return (
                    <RenderSubMenu
                      key={ind}
                      menu={m}
                      showsMenu={showSubMenu}
                      style={subMenuDirection === "row" ? " mx-10 " : ""}
                      textStyle={
                        subMenuDirection === "row" ? "" : "text-[10px] -ml-28"
                      }
                    />
                  );
                })}
              </ul>
            )}
          </Container>
        </Container>
      )}
    </>
  );
};

export default Header;
